import { helperMixin, infinityScrollTable } from "@/mixins";
import { defineComponent } from "vue";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import { QnaStatus } from "@/enums";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import PreviewModal from "@/components/PreviewModal.vue";
import { templateHttp } from "@/http/template";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import { toast } from "@/template/app";
export default defineComponent({
    data: () => ({
        confirmDeleteModal: false,
        QnaStatus,
    }),
    components: {
        DropdownItem,
        PreviewModal,
        BaseFormGroup,
        BaseButtonDanger,
        BaseButtonPrimary,
        ActionTableColumn,
        BaseTable,
        BaseTableRow,
        BaseTableColumn,
        ConfirmModal
    },
    mixins: [
        helperMixin,
        infinityScrollTable(templateHttp())
    ],
    methods: {
        async makeAsActive(id) {
            const { status, message } = await templateHttp().setActive(id);
            if (status !== 200) {
                toast("error", message, 0);
                return;
            }
            toast("success", message);
            this.reload();
        },
        statusColor(status) {
            if (status) {
                return 'bg-primary';
            }
            else {
                return 'bg-default';
            }
        }
    }
});
